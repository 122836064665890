import React from "react";
import axios from "axios";
import DataWrapper from "../../components/pages/experience/dataWrapper";
import ContentWrapper from "../../components/common/contentWrapper";

const Experience = () => {
  const [experienceData, setExperienceData] = React.useState();
  const [educationData, setEducationData] = React.useState();

  React.useEffect(() => {
    fetchExperienceData();
    fetchEducationeData();
  }, []);

  async function fetchExperienceData() {
    try {
      const response = await axios.get("assets/dataseeds/experience.json");
      setExperienceData(response.data);
    } catch (err) {
      console.log(err);
    }
  }
  async function fetchEducationeData() {
    try {
      const response = await axios.get("assets/dataseeds/education.json");
      setEducationData(response.data);
    } catch (err) {
      console.log(err);
    }
  }

  function renderExperience() {
    return experienceData?.experience?.map((items, index) => (
      <DataWrapper
        key={index}
        title={items?.title}
        subtitle={items?.companyName}
        description={items?.details}
        logo={items?.companyLogo}
      />
    ));
  }
  function renderEducation() {
    return educationData?.education?.map((items, index) => (
      <DataWrapper
        key={index}
        title={items?.institute}
        subtitle={`${items?.yearStart}-${items?.yearEnd}`}
        description={items?.details}
        logo={items?.logo}
      />
    ));
  }
  return (
    <div>
      <ContentWrapper
        title={experienceData?.title}
        description={experienceData?.description}
      >
        {renderExperience()}
      </ContentWrapper>
      <ContentWrapper
        title={educationData?.title}
        description={educationData?.description}
      >
        {renderEducation()}
      </ContentWrapper>
    </div>
  );
};

export default Experience;
