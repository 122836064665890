import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "../screens/home";
import Project from "../screens/project";
import Experience from "../screens/experience";
import Blog from "../screens/blog";
import Header from "../components/common/header";

const Routing = () => {
  return (
    <HashRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Project />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/blog" element={<Blog />} />
      </Routes>
    </HashRouter>
  );
};

export default Routing;
