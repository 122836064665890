import React from "react";

const ContentWrapper = ({ title, description, children }) => {
  return (
    <div>
      <section className="hero-section spacer-b">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hero-wrapper common-pages">
                <h1 className="theme-txt-clr">{title}</h1>
                <div className="text-wrapper w-sm-max">
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="common-container">
          <div className="container">
            <div className="row justify-content-center mt-5">
              <div className="col-xxl-11">
                <div className="row g-4">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContentWrapper;
