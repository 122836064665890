const headersArray=[
    {
        name:"Home",
        path:"/",
    },
    {
        name:"Projects",
        path:"/projects",
    },
    {
        name:"Experience",
        path:"/experience",
    },
    {
        name:"Blog",
        path:"/blog",
    },
]
export {
    headersArray
}