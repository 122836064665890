import React from "react";
import ContentWrapper from "../../components/common/contentWrapper";

const Blog = () => {
  return (
    <ContentWrapper title={"Blog"} description={"Coming Soon!"}>
      <div className="col-md-12">
        <div className="blog-card-wrapper">
          <p>Blog Posts</p>
        </div>
      </div>
      <div className="col-md-12">
        <div className="blog-card-wrapper">
          <p>Coming Soon!</p>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default Blog;
