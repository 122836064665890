import React from "react";

const DataWrapper = ({ title, subtitle, description, logo, link }) => {
  return (
    <div className="col-md-6">
      <div className="projects-card-link">
        <div className="projects-card-wrapper">
          <div className="project-card-img">
            <img src={logo} alt="projects" />
          </div>
          <div className="project-info" onClick={() => window.open(link)}>
            <h2>{title}</h2>
            <h3>{subtitle}</h3>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataWrapper;
