/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { headersArray } from "../../utils/seeds";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const nav = useNavigate();
  const location = useLocation();

  const [show, setShow] = useState(false);

  function handleNavigate(path) {
    nav(path);
    setShow(false);
  }

  function isLinkActive(path) {
    return location.pathname === path;
  }
  function renderHeaderList() {
    return headersArray?.map((items, index) => (
      <li key={index}>
        <button
          onClick={() => handleNavigate(items.path)}
          className={isLinkActive(items?.path) ? "navlink active" : "navlink"}
        >
          {items.name}
        </button>
      </li>
    ));
  }

  return (
    <div>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="header-flex-box">
                <button className="theme-clr-toggle" />
                <div className={show ? "nav-wrapper show" : "nav-wrapper"}>
                  <ul className="navlist">{renderHeaderList()}</ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="sm-screen-fixed-bar d-flex d-md-none">
        <div className="loc-time-info">
          <a className="location-link">
            <em>New York · GMT4</em>
          </a>
          <p className="current-time" id="liveTime" />
        </div>
        <button className="menubtn" onClick={() => setShow(!show)}>
          Menu
        </button>
      </div>
    </div>
  );
};

export default Header;
