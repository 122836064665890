import React from "react";

const DataWrapper = ({ title, subtitle, description, logo }) => {
  return (
    <div className="col-md-6">
      <div className="experience-card-wrapper">
        <div className="experience-card-header">
          <div className="experience-card-img">
            <img src={logo} alt="img" />
          </div>
          <div className="experience-titles">
            <h2>{title}</h2>
            <h3>{subtitle}</h3>
          </div>
        </div>
        <div className="experience-content">
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default DataWrapper;
