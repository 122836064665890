/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import {
  FileIcon,
  GithubIcon,
  InstagramIcon,
  LinkedinIcon,
  MailIcon,
} from "../../components/svg";

const Home = () => {
  return (
    <div>
      <section className="hero-section spacer-b">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hero-wrapper">
                <h1>
                  Engineer with a passion for
                  <span className="theme-txt-clr"> Sports</span> and
                  <span className="theme-txt-clr"> Computer Science</span>
                </h1>
                <div className="profile-info">
                  <div className="profile-img">
                    <img src="assets/images/shiven.png" alt="err" />
                  </div>
                  <div className="text-wrapper">
                    <p>Hey, I’m Shiven Sharma.</p>
                    <p>
                      I love volunteering, teaching and building projects at the
                      intersection of and computer science.
                    </p>
                    <p>
                      Degree in <u>Computer Science</u> &nbsp; from &nbsp;
                      <span className="decoration-dotted">
                        Boston University.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="social-media-wrapper">
                  <a
                    href="https://www.instagram.com/shivenn.s/"
                    aria-label="resume"
                    className="social-theme-btn"
                    target="_blank"
                  >
                    <FileIcon />
                  </a>
                  <a
                    href="https://github.com/shivensh9"
                    aria-label="github account"
                    className="social-theme-btn"
                    target="_blank"
                  >
                    <GithubIcon />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/shiven-sharma-9477a6248/"
                    aria-label="linkedin account"
                    className="social-theme-btn"
                    target="_blank"
                  >
                    <LinkedinIcon />
                  </a>
                  <a
                    href="https://www.google.com/intl/en-US/gmail/about/"
                    aria-label="mail link"
                    className="social-theme-btn"
                    target="_blank"
                  >
                    <MailIcon />
                  </a>
                  <a
                    href="https://www.instagram.com/shivenn.s/"
                    aria-label="instagram account"
                    className="social-theme-btn"
                    target="_blank"
                  >
                    <InstagramIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
