import React from "react";
import axios from "axios";
import ContentWrapper from "../../components/common/contentWrapper";
import DataWrapper from "../../components/pages/project/dataWrapper";

const Project = () => {
  const [projectData, setProjectData] = React.useState(null);

  React.useEffect(() => {
    fetchProjectData();
  }, []);

  async function fetchProjectData() {
    try {
      const response = await axios.get("assets/dataseeds/project.json");
      setProjectData(response?.data);
    } catch (err) {
      console.log(err);
    }
  }

  function renderProjects() {
    return projectData?.projects?.map((items, index) => (
      <DataWrapper
        key={index}
        title={items?.projectType}
        subtitle={items?.name}
        description={items?.description}
        logo={items?.image}
        link={items?.link}
      />
    ));
  }
  return (
    <ContentWrapper
      title={projectData?.title}
      description={projectData?.description}
    >
      {renderProjects()}
    </ContentWrapper>
  );
};
export default Project;
